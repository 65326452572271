<template>
  <div class="containe">
    <div class="user-box">
      <div class="user-item" v-for="item in props.list" :key="item" @click="privater(item)">
        <img :src="item.portrait" alt="" />
        <p>{{ item.nickname }}</p>
        <!-- <p v-else>******</p> -->
      </div>
      <!-- <div class="user-item" @click="add">
        <el-icon style="cursor: pointer; width: 48px; height: 48px; border: 1px dashed #aaa">
          <Plus />
        </el-icon>
        <p>添加</p>
      </div> -->
    </div>
    <div class="line"></div>
    <div class="btn" @click="exit">退出群聊</div>
    <!-- <el-dialog v-model="dialogVisible" width="30%" :show-close="false">
      <div class="dialog-box">
        <div class="search">
          <el-input
            type="text"
            v-model="keyword"
            class="w-50 m-2"
            size="large"
            placeholder="请输入用户账户"
          >
            <template #suffix>
              <el-icon class="el-input__icon">
                <component :is="Search" class="enter-sj" @click="loadMap"></component>
              </el-icon>
            </template>
          </el-input>
        </div>
        <div class="user" v-if="userInfo">
          <div @click="selectUser">
            <p class="name">{{ userInfo.nickname }}</p>
            <p class="mobile">{{ userInfo.mobile }}</p>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancellation">取消</el-button>
        </span>
      </template>
    </el-dialog> -->
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { Plus, Search } from '@element-plus/icons-vue'
import { chatGroup, joinGroup, exitGroup } from '@/api/service'
const props = defineProps({
  list: Array,
  groupId: Number
})
const emit = defineEmits('userChange')
const exit = () => {
  exitGroup({ group_id: props.groupId }).then((res) => {
    if (res.code == 0) {
      emit('userChange', 'exit')
    }
  })
}
const privater = (i) => {
  if (i.user_type == 3) {
    console.log(i)
    emit('userChange', 'privately', i.user_id, i.user_type)
  }
}
</script>
<style lang="less" scoped>
.containe {
  position: absolute;
  top: 44px;
  right: 0;
  width: 260px;
  height: 555px;
  background: #ffffff;
  padding: 0 16px;
  box-shadow: -2px 0px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px 0px 0px 20px;
  .user-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 24px;
    .user-item {
      width: 48px;
      text-align: center;
      margin-right: 12px;
      img {
        width: 48px;
        height: 48px;
      }
      p {
        width: 48px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
    .user-item:nth-of-type(4n + 0) {
      margin-right: 0;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    border: 1px solid #f2f2f2;
    margin: 40px 0;
  }
  .btn {
    width: 188px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #f24747;
    margin: 0 auto;
    background: #f1f2f3;
    border-radius: 8px;
    cursor: pointer;
  }
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset !important;
}
</style>
