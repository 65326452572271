<template>
  <div class="emoji">
    <el-popover trigger="click" placement="top" width="300px">
      <div class="emoji-line" v-for="(line, i) in emoji" :key="i">
        <div
          class="emoji-line-item"
          v-for="(item, index) in line"
          :key="index"
          @click="clickItem(item)"
        >
          {{ item }}
        </div>
      </div>
      <template #reference>
        <img src="../../assets/images/expression-icon.png" alt="" />
      </template>
    </el-popover>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
const props = defineProps({
  visible: Boolean
})
const emit = defineEmits('emojiMsg')
const emoji = ref([
  [
    '😀',
    '😁',
    '😂',
    '🤣',
    '😃',
    '😅',
    '😆',
    '😉',
    '😊',
    '😋',
    '😎',
    '😍',
    '😘',
    '😗',
    '😙',
    '😚',
    '🙂',
    '🤗',
    '🤔',
    '😐',
    '😑',
    '😶',
    '🙄',
    '😏',
    '😣',
    '😥',
    '😮',
    '🤐',
    '😯',
    '😪',
    '😫',
    '😴',
    '😌',
    '😛',
    '😜',
    '😝',
    '🤤',
    '😒',
    '😓',
    '😔',
    '😕',
    '🙃',
    '🤑',
    '😲',
    '🙁',
    '😖',
    '😟',
    '😤',
    '😢',
    '😭',
    '😧',
    '😨',
    '😩',
    '😬',
    '😰',
    '😳',
    '😱',
    '😵',
    '😡',
    '😠',
    '😷',
    '🤒',
    '🤕',
    '🤢',
    '🤧',
    '😇',
    '👻',
    '💀',
    '💩',
    '😈',
    '💋',
    '👄',
    '🌂',
    '👗',
    '🚫',
    '👜',
    '💼',
    '💍',
    '👠',
    '💯',
    '🛌',
    '💣',
    '👨🏻',
    '👩🏻',
    '🛍',
    '🎉',
    '🛀',
    '🎁',
    '💄',
    '🎅🏼',
    '🙅🏻‍',
    '🙆🏻',
    '🙋🏻',
    '🙇🏻',
    '🤦🏻',
    '🤷🏻',
    '💆🏻',
    '💇🏻',
    '📷',
    '💪',
    '👈',
    '👉',
    '👆',
    '👇',
    '🤘',
    '✌',
    '🤙',
    '👌',
    '👍',
    '👎',
    '👊',
    '👋',
    '✍',
    '👏',
    '🙏',
    '💅',
    '💗',
    '🏃🏻‍',
    '💔',
    '👯',
    '💑',
    '👪',
    '🙈',
    '💥',
    '💦',
    '🐒',
    '🐶',
    '🦊',
    '🐱',
    '🦁',
    '🐯',
    '🐴',
    '🦄',
    '🐎',
    '🐮',
    '🐷',
    '🐏',
    '🐫',
    '🐘',
    '🐹',
    '🐰',
    '🐨',
    '🐼',
    '🐾',
    '🐔',
    '🐥',
    '🐧',
    '🐸',
    '🐳',
    '🐲',
    '🐬',
    '🐠',
    '🐡',
    '🐟',
    '🐙',
    '🐚',
    '🦀',
    '🦐',
    '🦑',
    '🐌',
    '🦋',
    '🐜',
    '🐞',
    '🐝',
    '🕸',
    '🕷',
    '🌹',
    '🥀',
    '🌻',
    '🎄',
    '🌴',
    '🌵',
    '🍀',
    '🌞',
    '🌏',
    '⛅',
    '🌧',
    '🌈',
    '🌨',
    '⛄',
    '🔥',
    '💧',
    '☔',
    '🎃',
    '🍉',
    '🍌',
    '🍍',
    '🍎',
    '🍒',
    '🍓',
    '🥝',
    '🥑',
    '🍆',
    '🌽',
    '🍗',
    '🍔',
    '🍚',
    '🍦',
    '🎂',
    '🍭',
    '☕',
    '🍷',
    '🍺',
    '🍹',
    '🏇',
    '⛷',
    '🏌',
    '🚣🏻',
    '🏊',
    '🏆',
    '⛹',
    '🚴',
    '🤽',
    '🏅',
    '🏐',
    '🏀',
    '⚽',
    '⚾',
    '🏈',
    '🎾',
    '🎳',
    '🏓',
    '🏸',
    '⛸',
    '🎣',
    '🎯',
    '🎮',
    '🎲',
    '🎨',
    '🎧',
    '🎬',
    '🏝',
    '🏩',
    '🚃',
    '🚍',
    '🚘',
    '🚲',
    '✈'
  ]
])
const clickItem = (e) => {
  emit('emojiMsg', e)
}
</script>

<style lang="less" scoped>
.emoji {
  // width: 100%;

  padding: 16rpx 10rpx 185rpx 10rpx;
  box-sizing: border-box;

  cursor: pointer;
}
.emoji-line {
  height: 220px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto;
  .emoji-line-item {
    flex: 1;
    text-align: center;
    font-size: 40rpx;
    line-height: 80rpx;
    cursor: pointer;
    margin: 5px;
  }
}
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: none;
  background: #0b1a2c01;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 1px;
  background: #0b1a2c01;
  -webkit-box-shadow: none;
}
</style>
