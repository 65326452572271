<template>
  <div class="upload-box">
    <el-upload
      class="image-upload"
      action="#"
      :show-file-list="false"
      :auto-upload="false"
      :before-upload="beforeAvatarUpload"
      :on-change="handleChange"
      multiple
    >
      <img src="../../assets/images/upload-icon.png" alt="" />
    </el-upload>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { upload } from '@/api/goods'
import axios from 'axios'
import { ElMessage } from 'element-plus'
const emit = defineEmits('msgImage')
const beforeAvatarUpload = (rawFile) => {
  if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
    ElMessage.error('仅支持jpg、png格式!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
const handleChange = (file) => {
  let fileArr = file.name.split('.')
  let fileType = fileArr[fileArr.length - 1]
  upload().then((res) => {
    if (res.code == 200) {
      let code = ''
      const codeLength = 12
      const random = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ]
      for (let i = 0; i < codeLength; i++) {
        let index = Math.floor(Math.random() * 26)
        code += random[index]
      }
      let formData = new FormData()
      formData.append('key', res.data.dir + '/' + code + '.' + fileType)
      formData.append('OSSAccessKeyId', res.data.accessid)
      formData.append('policy', res.data.policy)
      formData.append('Signature', res.data.signature)
      formData.append('file', file.raw)
      axios({
        method: 'post',
        url: res.data.host,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        emit('msgImage', response.request.responseURL + res.data.dir + '/' + code + '.' + fileType)
        // console.log(response.request.responseURL + res.data.dir + '/' + code + '.' + fileType)
        // imageUrl.value = response.request.responseURL + res.data.dir + '/' + code
        // coverPhoto.value = res.data.dir + '/' + code
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}
</script>
<style lang="less" scoped>
.upload-box {
  height: 30px;
  margin-left: 24px;
}
</style>
