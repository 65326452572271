<template>
  <div>
    <topNav></topNav>
    <div class="mess">
      <!--用户列表-->
      <div class="mess_user_list">
        <!--用户本人-->
        <!-- <div class="user">
          <el-avatar :size="40" :src="userAvatar" style="margin: 5px"></el-avatar>
          <span>{{ userName }}</span>
        </div> -->
        <!--其他用户或群-->
        <div class="user_list" v-if="state.userList.length > 0">
          <div
            v-for="(item, index) in state.userList"
            :key="index"
            @click="showmessdlog(item, index)"
            class="user_list_item"
            :class="
              state.current == item.group_id ? 'active' : '' || item.is_top > 0 ? 'bg-color' : ''
            "
            @contextmenu.prevent="openMenu($event, item)"
          >
            <div class="avatar-box">
              <el-avatar :size="40" :src="item.portrait" style="margin: 5px"></el-avatar>
              <p v-if="item.unread > 0">{{ item.unread }}</p>
            </div>
            <div style="width: 210px">
              <div class="leftCont">
                <p>{{ item.title }}</p>
                <p style="text-align: right">
                  {{ timestampFormat(item.update_time) || '--:--' }}
                </p>
              </div>
              <div class="rightCont">
                <p
                  v-if="item.msg_type == 1"
                  v-html="delHtmlTag(item.msg)"
                  :style="{ color: item.isRemind ? 'red' : '' }"
                ></p>
                <p v-if="item.msg_type == 2">[图片]</p>
                <p v-if="item.msg_type == 3">[订单]</p>
              </div>
            </div>
            <ul
              v-if="rightClickItem"
              v-show="state.visible"
              :style="{
                left: position.left + 'px',
                top: position.top + 'px',
                display: visible ? 'block' : 'none'
              }"
              class="contextmenu"
            >
              <div class="item" v-show="rightClickItem.is_top == 0" @click.stop="copySvg(0)">
                置顶
              </div>
              <div class="item" v-show="rightClickItem.is_top > 0" @click.stop="copySvg(1)">
                取消置顶
              </div>
              <div class="item" @click.stop="copySvg(2)">删除聊天</div>
            </ul>
          </div>
          <div style="text-align: center; padding: 5px 0; cursor: pointer" @click="loadingClick">
            加载更多~
          </div>
        </div>
      </div>
      <!--有对话时，对话框-->
      <div v-if="state.acceptUser !== ''" class="mess_dialog">
        <!--对话框头部-->
        <div class="dlog_header">
          <div>
            <span>{{ state.acceptUser }}</span>
            <div style="display: inline-block" v-if="state.socketType == 'single'">
              <div
                style="
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #00e152;
                  margin-left: 5px;
                "
                v-if="state.is_online == 1"
              ></div>
              <div
                style="
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #bfc2cb;
                  margin-left: 5px;
                "
                v-if="state.is_online == 0"
              ></div>
            </div>
          </div>
          <el-icon style="cursor: pointer" v-if="state.more" @click="moreClick">
            <MoreFilled />
          </el-icon>
        </div>
        <!--对话框内容-->
        <div class="dlog_content" ref="messageBox" @scroll="msgScroll">
          <div
            v-for="(item, index) in state.msgList"
            :key="index"
            class="dlog_content_item"
            style="margin-left: 5px"
          >
            <!--其他用户的消息展示-->
            <div class="content_other" v-if="item.from_user_id != user.id">
              <img :src="item.from_user.portrait" alt="" class="avatar" />
              <div class="content_msg">
                <div style="display: flex">
                  <p>{{ item.create_time }}</p>
                  <p>{{ item.from_user.nickname }}</p>
                </div>
                <!-- <p v-else>******</p> -->
                <p
                  class="msg"
                  @click="hrefClick(item)"
                  v-if="item.type == 1"
                  v-html="item.msg"
                  style="
                    margin: 0px;
                    margin-top: 4px;
                    color: #1a1a1a;
                    font-size: 12px;
                    max-width: 400px;
                    word-wrap: break-word;
                  "
                  :style="{ color: item.msg.indexOf(user.nickname) != -1 ? 'red' : '' }"
                ></p>
                <template v-if="item.type == 2">
                  <el-image
                    v-if="item.isImg == true"
                    style="max-width: 400px; max-height: 300px"
                    :src="item.msg"
                    :zoom-rate="1.2"
                    :preview-src-list="[item.msg]"
                    fit="cover"
                  />
                  <video
                    v-else
                    :src="item.msg"
                    alt=""
                    controls
                    autoplay
                    style="max-width: 400px; max-height: 300px"
                  ></video>
                </template>
                <div v-if="item.type == 3" class="card" @click="orderClick(item.msg)">
                  <img :src="item.msg.image" alt="" />
                  <div>
                    <div class="title">{{ item.msg.title }}</div>
                    <div>商品编号:{{ item.msg.id }}</div>
                    <div class="price">￥{{ item.msg.price / 100 }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--本用户的消息展示-->
            <div class="content_me" v-if="item.from_user_id == user.id">
              <div class="content_msg">
                <div style="display: flex">
                  <p>{{ item.create_time }}</p>
                  <p>{{ user.nickname }}</p>
                </div>
                <p
                  class="msg"
                  style="
                    margin: 0px;
                    margin-top: 4px;
                    color: #1a1a1a;
                    font-size: 12px;
                    max-width: 400px;
                    word-wrap: break-word;
                  "
                  v-html="item.msg"
                  v-if="item.type == 1"
                  @click="hrefClick(item)"
                ></p>
                <template v-if="item.type == 2">
                  <el-image
                    v-if="item.isImg == true"
                    style="max-width: 400px; max-height: 300px"
                    :src="item.msg"
                    :zoom-rate="1.2"
                    :preview-src-list="[item.msg]"
                    fit="cover"
                  />
                  <video
                    v-else
                    :src="item.msg"
                    alt=""
                    controls
                    autoplay
                    style="max-width: 400px; max-height: 300px"
                  ></video>
                </template>
                <div v-if="item.type == 3" class="card" @click="orderClick(item.msg)">
                  <img :src="item.msg.image" alt="" />
                  <div>
                    <div class="title" style="margin-top: 0">{{ item.msg.title }}</div>
                    <div>商品编号:{{ item.msg.id }}</div>
                    <div class="price">￥{{ item.msg.price / 100 }}</div>
                  </div>
                </div>
              </div>
              <img :src="item.from_user.portrait" alt="" class="avatar" />
            </div>
          </div>
          <!-- 商品卡片 -->
          <div class="container" v-if="cardShow">
            <img :src="goodsInfo.image" alt="" />
            <div class="goods-info">
              <div style="display: flex; justify-content: space-between">
                <p class="card-title">{{ goodsInfo.title }}</p>
                <el-icon style="cursor: pointer" @click="delCard"><Close /></el-icon>
              </div>
              <p>商品编号:{{ goodsInfo.id }}</p>
              <div
                style="display: flex; justify-content: space-between; margin-top: 5px; width: 300px"
              >
                <p class="price">￥{{ goodsInfo.price / 100 }}</p>
                <p class="btn" @click="card">发送</p>
              </div>
            </div>
          </div>
        </div>

        <!--对话框底部-->
        <div class="dlog_footer">
          <!-- <el-input type="textarea" :rows="5" v-model="state.mess"></el-input> -->
          <div class="img">
            <emoji @emojiMsg="emojiMsg"></emoji>
            <serviceUpload @msgImage="msgImage"></serviceUpload>
            <!-- <img src="../../assets/images/upload-icon.png" alt="" /> -->
          </div>
          <!-- <textarea name="" id="" cols="88" rows="4"></textarea> -->
          <div
            :contenteditable="true"
            class="text-area"
            ref="edit"
            id="box"
            @paste="handlePaste($event)"
            @input="changeText($event.target.innerHTML, $event)"
            @keydown.enter="Wssendmess"
            @keyup="remind($event)"
            @blur="setValue($event)"
          ></div>
          <div class="group-box" v-show="state.remindShow">
            <div
              class="item"
              v-for="(i, index) in state.groupUserList"
              :key="index"
              @click="selectRemind(i)"
            >
              {{ i.nickname }}
            </div>
          </div>
          <div style="text-align: right">
            <el-button type="primary" @click="Wssendmess">发送</el-button>
          </div>
        </div>
        <group-user
          v-if="state.groupShow"
          :list="state.groupUserList"
          :groupId="state.groupId"
          @userChange="selectChang"
        ></group-user>
      </div>
      <!--无对话时，对话框-->
      <div v-else class="mess_dialog_false">
        <span>暂无消息，请选择用户对象</span>
      </div>
    </div>
    <LayoutFooter></LayoutFooter>
  </div>
</template>
<script setup>
import topNav from '../Layout/components/top-nav.vue'
import LayoutFooter from '../Layout/components/layout-footer.vue'
import emoji from '../../components/emoji/emoji.vue'
import serviceUpload from '../../components/service-upload/service-upload.vue'
import groupUser from '../../components/group-user/group-user.vue'
import {
  ref,
  reactive,
  onUnmounted,
  onMounted,
  onUpdated,
  watch,
  nextTick,
  onBeforeUnmount
} from 'vue'
import { MoreFilled } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import {
  chatGroup,
  chatLogSingle,
  chatLogGroup,
  chatLogPage,
  chatGroupPage,
  operateMsg,
  groupMembers,
  operateGroup
} from '@/api/service'
import { timestampFormat } from '@/utils/formatDate'
import { findGoods } from '@/api/goods'
import { Plus, Close } from '@element-plus/icons-vue'
const route = useRoute()
const router = useRouter()
const user = JSON.parse(localStorage.getItem('userInfo'))
const state = reactive({
  //左侧列表
  userList: [],
  acceptUser: '',
  current: 0, //选中当前
  mess: '', //输入框内容
  visible: false,
  msgList: [],
  account: '', //创建聊天参数
  groupId: null, //聊天组id
  uid: '', //消息接收者
  // userAvatar: 'https://gameossv1-20230424.oss-cn-hangzhou.aliyuncs.com/' + user.portrait, //当前登录账号头像
  msgType: 1, //消息类型
  more: false,
  groupUserList: [], //群成员列表数据
  groupShow: false, //是否显示群聊详情
  socketType: '', //聊天类型
  remindShow: false,
  is_online: null, //客服是否在线
  remindColor: false
})
const rightClickItem = ref()
const position = ref({
  top: 0,
  left: 0
})
function delHtmlTag(str) {
  return str.replace(/<[^>]+>/g, '')
}
//右键菜单
const openMenu = (e, i) => {
  console.log(e)
  state.visible = true
  position.value.top = e.layerY
  position.value.left = e.layerX
  rightClickItem.value = i
}
//会话操作
const copySvg = (val) => {
  let type = null
  if (val == 0 || val == 1) {
    type = 1
  } else if (val == 2) {
    type = 0
  }
  operateGroup({ group_id: rightClickItem.value.group_id, type: type }).then((res) => {
    if (res.code == 0) {
      // state.current = 0
      // getUserList()
      state.visible = false
      chatGroupPage({ page: 1 }).then((reslut) => {
        if (reslut.code == 0) {
          state.userList = reslut.data.data
        }
      })
    }
  })
}
const closeMenu = () => {
  state.visible = false
}
watch(
  () => state.visible,
  (val) => {
    if (val) {
      document.body.addEventListener('click', closeMenu)
    } else {
      document.body.removeEventListener('click', closeMenu)
    }
  }
)
const messageBox = ref()
const msgScroll = (e) => {
  const clientHeight = e.target.clientHeight
  const scrollHeight = e.target.scrollHeight
  const scrollTop = e.target.scrollTop
  // console.log(scrollHeight)
  // console.log(clientHeight)
  // console.log(scrollTop)
  if (scrollTop == 0) {
    msgpage.value++
    getChatLog()
  }
}
const loadingClick = () => {
  userpage.value++
  chatGroupPage({ page: userpage.value }).then((res) => {
    if (res.code == 0) {
      if (res.data.data.length > 0) {
        state.userList = [...state.userList, ...res.data.data]
      }
    }
  })
}
const scoket = reactive({
  ws: null,
  isConnected: false,
  heartbeatInterval: null // 保存心跳定时器的ID
})
const initConnect = () => {
  scoket.ws = new WebSocket('wss://kf-api.sybaopei.com/socket/')
  scoket.ws.onopen = () => {
    console.log('WebSocket连接已建立')
    scoket.ws.send(
      JSON.stringify({
        type: 'bind',
        token: localStorage.getItem('token')
      })
    )
    scoket.isConnected = true
    scoket.heartbeatInterval = setInterval(() => {
      if (scoket.ws.readyState === 1) {
        scoket.ws.send(JSON.stringify({ type: 'ping' }))
        console.log('心跳开启')
      } else {
      }
    }, 30000)
  }
  scoket.ws.onmessage = (event) => {
    // console.log(event)
    const dataMsg = JSON.parse(event.data)
    // console.log(dataMsg, '接收到的信息')
    if (dataMsg.code == 200) {
      if (dataMsg.type == 'msg' || dataMsg.type == 'conversation') {
        getUserList()
      }
    }
  }
  scoket.ws.onerror = () => {
    console.error('WebSocket连接发生错误')
  }
  scoket.ws.onclose = () => {
    console.log('WebSocket连接已关闭')
    scoket.ws.send('close')
    scoket.isConnected = false
    // 清除心跳定时器
    clearInterval(scoket.heartbeatInterval)
    scoket.ws.onopen = () => {
      console.log('WebSocket连接已建立')
      scoket.ws.send(
        JSON.stringify({
          type: 'bind',
          token: localStorage.getItem('token')
        })
      )
      scoket.isConnected = true
      scoket.heartbeatInterval = setInterval(() => {
        if (scoket.ws.readyState === 1) {
          scoket.ws.send(JSON.stringify({ type: 'ping' }))
          console.log('心跳开启')
        } else {
        }
      }, 30000)
    }
  }
}
onBeforeUnmount(() => {
  scoket.ws.onclose = () => {
    console.log('WebSocket连接已关闭')
    scoket.ws.send('close')
    scoket.isConnected = false
    // 清除心跳定时器
    clearInterval(scoket.heartbeatInterval)
  }
})
watch(
  () => scoket.isConnected,
  (val) => {
    if (val == false) {
      initConnect()
    }
  }
)
const hrefClick = (i) => {
  if (i.msgurl) {
    window.open(i.msgurl)
  }
}
const getChatLog = () => {
  chatLogPage({ page: msgpage.value, group_id: state.groupId }).then((res) => {
    if (res.code == 0) {
      if (res.data.data.length > 0) {
        let reg =
          /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi
        for (let i = 0; i < res.data.data.length; i++) {
          res.data.data[i].msgurl = ''
          if (res.data.data[i].type == 3) {
            res.data.data[i].msg = JSON.parse(res.data.data[i].msg)
          }
          res.data.data[i].isImg = false
          if (res.data.data[i].type == 2) {
            let arr = res.data.data[i].msg.split('.')
            if (
              arr[arr.length - 1] == 'png' ||
              arr[arr.length - 1] == 'jpg' ||
              arr[arr.length - 1] == 'jpeg' ||
              arr[arr.length - 1] == 'webp' ||
              arr[arr.length - 1] == 'gif' ||
              arr[arr.length - 1] == 'bmp'
            ) {
              res.data.data[i].isImg = true
            }
          }
          if (res.data.data[i].from_user_type != 3) {
            const idCard = res.data.data[i].from_user.nickname
            const temp = idCard.substring(1)
            res.data.data[i].from_user.nickname = idCard.replace(temp, '****')
            console.log(res.data.data[i].from_user.nickname)
          }
          if (res.data.data[i].type == 1) {
            if (reg.test(res.data.data[i].msg)) {
              const urlreg =
                /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g
              const str = res.data.data[i].msg.match(urlreg)
              if (str && str.length > 0) {
                res.data.data[i].msgurl = str[0]
              }
            }
          }
        }
        state.is_online = res.data.is_online
        if (msgpage.value == 1) {
          state.msgList = res.data.data
          nextTick(() => {
            messageBox.value.scrollTop = messageBox.value.scrollHeight
          })
        } else {
          // state.msgList = [...res.data.data, ...state.msgList]
          res.data.data.reverse().map((e) => {
            state.msgList.unshift(e)
          })
        }
        console.log(state.msgList)
      }
    }
  })
}
onUnmounted(() => {
  console.log('222222')
  scoket.ws.onclose = () => {
    console.log('WebSocket连接已关闭')
    scoket.ws.send('close')
    scoket.isConnected = false
    clearInterval(scoket.heartbeatInterval)
  }
})
onMounted(() => {
  initConnect()
})
onUpdated(() => {})
const edit = ref(null)
//获取聊天列表
const msgpage = ref(1)
const userpage = ref(1)
const getUserList = () => {
  chatGroupPage({ page: userpage.value }).then((res) => {
    if (res.code == 0) {
      if (res.data.data.length > 0) {
        if (state.groupId) {
        } else {
          res.data.data.forEach((i, index) => {
            if (i.user_id == route.query.account && i.user_type == 3) {
              state.current = i.group_id
              state.groupId = i.group_id
              state.acceptUser = i.title
              state.uid = i.uid
              if (i.group_type == 2) {
                state.more = true
                state.socketType = 'group'
              } else if (i.group_type == 1) {
                state.socketType = 'single'
              }
            } else {
            }
          })
        }
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].group_type == 2) {
            if (res.data.data[i].msg.indexOf(user.nickname) != -1) {
              res.data.data[i].isRemind = true
            } else {
              res.data.data[i].isRemind = false
            }
            try {
              if (state.groupId != res.data.data[i].group_id) {
                const lastMsg = state.userList.filter((e) => {
                  return e.group_id == res.data.data[i].group_id
                })[0]
                lastMsg.isRemind ? (res.data.data[i] = lastMsg) : null
              }
            } catch (e) {}
          }
        }
        state.userList = res.data.data
        getChatLog()
      }
    }
  })
}
//选择聊天对象
const showmessdlog = (i, index) => {
  msgpage.value = 0
  userpage.value = 1
  state.msgList = []
  state.acceptUser = i.title
  state.current = i.group_id
  state.groupId = i.group_id
  state.uid = i.uid
  state.groupShow = false
  if (i.group_type == 2) {
    state.more = true
  } else {
    state.more = false
  }
  if (edit.value) {
    edit.value.innerHTML = ''
  }
  if (i.group_type == 1) {
    state.socketType = 'single'
  } else if (i.group_type == 2) {
    state.socketType = 'group'
  }
  state.groupUserList = []
  if (i.user_id != route.query.account) {
    cardShow.value = false
  }
  getChatLog()
  if (i.unread > 0) {
    operateMsg({ group_id: i.group_id }).then((res) => {
      if (res.code == 0) {
        getUserList()
      }
    })
  }
}
const emojiMsg = (val) => {
  edit.value.innerText += val
}
const msgImage = (e) => {
  scoket.ws.send(
    JSON.stringify({
      type: 'send',
      msg: e,
      msg_type: 2,
      uid: state.uid,
      mode: state.socketType
    })
  )
  let params = {
    msg: e,
    msg_type: 2,
    group_id: state.groupId
  }
  chatLogSingle(params).then((res) => {
    if (res.code == 0) {
      getUserList()
    }
  })
}
const selectChang = (val, id, type) => {
  state.groupShow = false
  state.more = false
  if (val == 'exit') {
  } else {
    chatGroup({ id: id, type: type }).then((res) => {
      if (res.code == 0) {
      }
    })
  }
  getUserList()
}
const moreClick = () => {
  state.groupShow = !state.groupShow
  state.groupUserList = []
  if (state.groupShow == true) {
    groupMembers({ page: 1, limit: 100, group_id: state.groupId }).then((res) => {
      if (res.code == 0) {
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].user_type != 3) {
            const idCard = res.data.data[i].nickname
            const temp = idCard.substring(1)
            res.data.data[i].nickname = idCard.replace(temp, '****')
            console.log(res.data.data[i].nickname)
          }
        }
        state.groupUserList = res.data.data
      }
    })
  }
}
//获取商品详情
const goodsInfo = ref()
const cardShow = ref(false)
const getGoods = () => {
  findGoods(route.query.goodsid).then((res) => {
    if (res.code == 200) {
      res.data.image = res.data.image[0]
      goodsInfo.value = res.data
      console.log(goodsInfo.value)
      cardShow.value = true
    }
  })
}
if (route.query.goodsid) {
  getGoods()
}
//隐藏卡片
const delCard = () => {
  cardShow.value = false
}

// getUserList()
//创建聊天
const setChat = () => {
  chatGroup({ id: route.query.account, type: 3 }).then((res) => {
    if (res.code == 0) {
      getUserList()
    }
  })
}
setChat()
//发送按钮
const Wssendmess = () => {
  let params = {
    msg: edit.value.innerHTML,
    msg_type: 1,
    group_id: state.groupId
  }
  chatLogSingle(params).then((res) => {
    if (res.code == 0) {
      scoket.ws.send(
        JSON.stringify({
          type: 'send',
          msg: edit.value.innerHTML,
          msg_type: 1,
          uid: state.uid,
          mode: state.socketType
        })
      )
      edit.value.innerHTML = ''
      getUserList()
    }
  })
}
//发送卡片
const card = () => {
  let obj = {
    image: goodsInfo.value.image,
    price: goodsInfo.value.price,
    title: goodsInfo.value.title,
    id: goodsInfo.value.id
  }
  let params = {
    msg: JSON.stringify(obj),
    msg_type: 3,
    group_id: state.groupId
  }
  chatLogSingle(params).then((res) => {
    if (res.code == 0) {
      scoket.ws.send(
        JSON.stringify({
          type: 'send',
          msg: JSON.stringify(obj),
          msg_type: 3,
          uid: state.uid,
          mode: state.socketType
        })
      )
      getUserList()
    }
  })
}
const orderClick = (goods) => {
  router.push({ path: '/goods', query: { id: goods.id, type: 'buy' } })
}
const changeText = (e, val) => {
  console.log(val)
  // if (state.socketType == 'group') {
  //   state.groupUserList = []

  // }
  // 解决：末尾换行，看不见的<br>，删不掉，造成清空无法出现placeholder文字
  // if (this.$el.innerHTML == '<br>') {
  //   this.$el.innerHTML = ''
  // }
  // this.$emit('input', this.$el.innerHTML)
}
const remind = (e) => {
  if (e.key == '@') {
    groupMembers({ page: 1, limit: 100, group_id: state.groupId }).then((res) => {
      if (res.code == 0) {
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].user_type != 3) {
            const idCard = res.data.data[i].nickname
            const temp = idCard.substring(1)
            res.data.data[i].nickname = idCard.replace(temp, '****')
          }
        }
        state.groupUserList = res.data.data
        state.remindShow = true
      }
    })
  }
}
const selectRemind = (i) => {
  edit.value.innerText += i.nickname + ' '
  state.remindShow = false
}
const handlePaste = (e) => {
  console.log(e)
}
const setValue = (e) => {
  console.log(e)
}
</script>
<style lang="less" scoped>
.mess {
  border-radius: 5px;
  background-clip: padding-box;
  margin: 20px auto;
  width: 950px;
  height: 600px;
  border: 1px #8a8282;
  box-shadow: 0 0 10px #9b9393;
  background-color: white;
  display: flex;
  position: relative;
  .mess_user_list {
    width: 270px;
    height: 100%;
    overflow-y: scroll;
    //   background-color: #9f9c9c;
    .user {
      height: 60px;
      width: 270px;
      /*垂直居中*/
      display: flex;
      align-items: center;
      border-bottom: 1px solid #0a0a0a;
    }
    .user_list {
      height: 540px;
      // overflow-y: scroll;
    }
    .user_list_item {
      height: 76px;
      //   background-color: #b3b3b4;
      border-bottom: 1px solid #f6f8fb;
      display: flex;
      align-items: center;
      // justify-content: space-between;
    }
    .bg-color {
      background: rgb(228, 228, 230);
    }
    .active {
      background: url('~@/assets/images/current-bg.png') no-repeat;
      color: #ffffff;
      background-size: cover;
    }
    .avatar-box {
      position: relative;
      p {
        position: absolute;
        top: 0;
        right: 5px;
        background: #f93737;
        border-radius: 50%;
        font-size: 12px;
        color: #ffffff;
        padding: 0 3px;
      }
    }
    .leftCont {
      display: flex;
      justify-content: space-between;
      p {
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
    .rightCont {
      display: flex;
      justify-content: space-between;
      p:nth-child(1) {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
  }
  .mess_dialog {
    width: 680px;
    height: 600px;
    position: relative;
    .dlog_header {
      width: 680px;
      height: 44px;
      //   border-bottom: 1px solid #8a8282;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f6f8fb;
      padding: 0 30px;
    }
    .dlog_content {
      width: 680px;
      height: 370px;
      background: #f1f2f3;
      //   border-bottom: 1px solid #8a8282;
      overflow-y: scroll;
      padding: 20px;
      .dlog_content_item {
        margin-bottom: 10px;
      }
      .content_other {
        // width: 650px;
        display: flex;
        // align-items: center;
        .avatar {
          width: 40px;
          height: 40px;
        }
        .content_msg {
          margin-left: 8px;
          // p:nth-child(1) {
          //   font-size: 14px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          //   font-weight: 400;
          //   color: #666666;
          // }
          .msg {
            padding: 10px;
            background: #ffffff;
            border-radius: 4px 12px 12px 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
          }
        }
      }
      .content_me {
        // width: 650px;
        display: flex;
        // align-items: center;
        justify-content: end;
        .avatar {
          width: 40px;
          height: 40px;
        }
        .content_msg {
          margin-right: 8px;
          // p:nth-child(1) {
          //   font-size: 14px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          //   font-weight: 400;
          //   color: #666666;
          //   text-align: right;
          // }
          .msg {
            padding: 10px;
            background: #ffffff;
            border-radius: 12px 4px 12px 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
          }
        }
      }
    }
    .dlog_footer {
      width: 680px;
      height: 180px;
      border-left: 1px solid #f5f6fa;
      padding: 15px;
      position: relative;
      .img {
        display: flex;
        // margin-bottom: 5px;
      }
      .text-area {
        height: 100px;
        overflow: auto;
        user-select: text;
        -webkit-user-select: text;
      }
      .text-area:focus-visible {
        border: none;
      }
      // :deep(.el-button--primary) {
      //   // margin-right: 20px;
      // }
      .group-box {
        background: #ffffff;
        padding: 0 10px 10px 10px;
        position: absolute;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        top: 40px;
        left: 40px;
        z-index: 999;
        border-radius: 10px;
        height: 200px;
        overflow-y: auto;
        .item {
          cursor: pointer;
          margin-top: 10px;
        }
      }
    }
  }
  .mess_dialog_false {
    width: 680px;
    height: 600px;
    text-align: center;
    line-height: 600px;
    border-left: 1px solid #f5f6fa;
  }
}
:deep(.el-textarea__inner) {
  resize: none;
}
[contenteditable]:focus {
  outline: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  background: #aaa;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 5px;
  background: #f1f2f3;
  -webkit-box-shadow: none;
}
.contextmenu {
  width: 100px;
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

  .item {
    padding: 0 15px;
    height: 35px;
    width: 100%;
    line-height: 35px;
    color: rgb(29, 33, 41);
    cursor: pointer;
  }
  .item:hover {
    background: rgb(229, 230, 235);
  }
}
.container {
  width: 403px;
  // height: 84px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  position: absolute;
  left: -220px;
  bottom: 200px;
  padding: 10px 12px;
  img {
    width: 66px;
    height: 66px;
  }
  .goods-info {
    margin-left: 8px;
    .card-title {
      width: 260px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .price {
      font-size: 16px;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #ff401b;
    }
    .btn {
      width: 60px;
      height: 20px;
      background: #0cb6ff;
      border-radius: 13px;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.card {
  display: flex;
  padding: 12px;
  width: 280px;
  // height: 104px;
  background: #ffffff;
  border-radius: 12px 4px 12px 12px;
  img {
    width: 80px;
    height: 80px;
    margin-right: 5px;
  }
  .title {
    width: 170px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .price {
    font-size: 16px;
    font-family: DIN-Bold, DIN;
    font-weight: bold;
    color: #ff401b;
    text-align: right;
  }
}
</style>
